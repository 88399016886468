import { Router } from 'aurelia-router';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { ApplicationState } from './application-state';
import { Product } from './product';
import { SiteConfig, Category, Item } from './models/site-config';
import { Cart, CartLine, CartItem } from './models/cart';
import { Netsuite } from './services/netsuite-service';
import { ProductGraphics } from './product-graphics';
import { ProductImage } from './product-image';

@autoinject()
export class CartView {

    assetsRootUrl: string;
    cart: Cart;
    minimumOrderMet: boolean;
    loading: boolean = true;

    constructor(
        public netsuite: Netsuite,
        public appState: ApplicationState,
        public router: Router,
        public eventAggregator: EventAggregator,
        public dialogService: DialogService) {
    }

    activate(params, routeConfig) {
        //console.log('params', params);
        //this.cart = this.appState._cart;
        this.appState.siteConfig.promise.then((site) => {
            this.assetsRootUrl = site.assetsRootUrl;
            this.appState.getCart().then((cart) => {
                this.cart = cart;
                if (cart.subtotal < 75) this.minimumOrderMet = false;
                else this.minimumOrderMet = true;

                this.loading = false;
            });
        });
    }

    viewProduct(item) {
        //console.log('open product dialog', item);
        this.dialogService.open({ viewModel: Product, model: { item: item, assetsRootUrl: this.assetsRootUrl } });
    }

    viewProductGraphics(item: Category) {
        //console.log('open product list', item);
        //this.router.navigateToRoute('product-graphics', { siteUrl: this.appState.siteUrl, categoryUrl: item.categoryIds[0], productUrl: item.url });
        //this.router.navigateToRoute('product-graphics', { categoryUrl: this.category.url, productUrl: item.url });// .dialogService.open({ viewModel: Product, model: { item: item, assetsRootUrl: this.assetsRootUrl } });
        this.dialogService.open({ viewModel: ProductGraphics, model: { productUrl: item.url } });
    }

    // viewProductImage(ver) {
    //     //console.log('open product dialog', ver);
    //     this.dialogService.open({ viewModel: ProductImage, model: { itemVersion: ver, assetsRootUrl: this.assetsRootUrl } });
    // }

    viewProductImage(item) {
        //console.log('open product dialog', ver);
        this.dialogService.open({
            viewModel: ProductImage, //'product-image',
            model: {
                item: item,
                assetsRootUrl: this.assetsRootUrl
            },
            lock: false
        });
    }

    checkout() {
        this.router.navigateToRoute('checkout');
    }

    removeItem(line: CartLine) {
        this.saving(true);
        return this.netsuite.editLineItemVersion(line, null, null, true).then(async (result) => {
            console.log('removeItem', result);
            if (result.success) {
                this.cart = await this.appState.loadCart(result.cart);
            }
            //this.addToCartBtn.disabled = false;
            this.saving(false);
        });
    }

    // updateItemQty(line: CartLine) {
    //     this.saving(true);
    //     return this.netsuite.editLineItem(line).then(async (result) => {
    //         //console.log('updateItemVersionQty', result);
    //         if (result.success) {
    //             this.cart = await this.appState.loadCart(result.cart);
    //         }
    //         //this.addToCartBtn.disabled = false;
    //         this.saving(false);
    //     });
    // }

    updateItemVersionQty(line: CartLine, ver: CartItem) {
        this.saving(true);
        return this.netsuite.editLineItemVersion(line, ver).then(async (result) => {
            console.log('updateItemVersionQty', result);
            if (result.success) {
                this.cart = await this.appState.loadCart(result.cart);
            }
            //this.addToCartBtn.disabled = false;
            this.saving(false);
        });
    }

    removeItemVersion(line: CartLine, ver: CartItem, subitem: CartItem) {
        this.saving(true);
        // ver.quantity = 0;
        return this.netsuite.editLineItemVersion(line, ver, subitem, true).then(async (result) => {
            console.log('removeItemVersion', result);
            if (result.success) {
                this.cart = await this.appState.loadCart(result.cart);
            }
            //this.addToCartBtn.disabled = false;
            this.saving(false);
        });
    }

    saving(start: boolean) {
        if (start) window.document.body.style.cursor = 'wait';
        else window.document.body.style.cursor = 'default';
    }
}

