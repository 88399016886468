import { inject } from 'aurelia-framework';
//import {EventAggregator} from 'aurelia-event-aggregator';
import { HttpClient } from "aurelia-http-client";
//import {HttpClient} from "aurelia-fetch-client";
//import {ApplicationState} from './application-state';
// import { SiteConfig, Category, Item, AddressModel } from '../models/site-config';
import { Cart, CartLine, CartItem } from '../models/cart';

//@inject(ApplicationState, EventAggregator)
@inject(HttpClient)
export class Netsuite {
	/*static inject = [HttpClient];
	constructor(http){
	  this.http = http;
	}*/
    // appState: ApplicationState;
    // eventAggregator: EventAggregator;

    private netsuite = {
        account: "4095715",
        urls: {
        },
        externalUrlsSandbox: {
            portal: 'https://forms.netsuite.com/app/site/hosting/scriptlet.nl?script=188&deploy=1&compid=4095715_SB1&h=84a71131bc450c03913d',
            auth: 'https://forms.netsuite.com/app/site/hosting/scriptlet.nl?script=102&deploy=1&compid=4095715_SB1&h=5ed98ef95f8c878d47e5'
        },
        externalUrlsProd: {
            portal: 'https://forms.na1.netsuite.com/app/site/hosting/scriptlet.nl?script=173&deploy=1&compid=4095715&h=2deacd56defaa4e44489',
            auth: 'https://forms.na1.netsuite.com/app/site/hosting/scriptlet.nl?script=102&deploy=1&compid=4095715&h=b56cd41067382fcc6980'
        }
    };

    http: HttpClient;
    // private searches = [];
    // private fieldlookups = [];
    // private customScripts = [];
    private urls;

    //constructor(appState, eventAggregator) {
    constructor(http) {
        this.http = http;
        // this.appState = appState;
        // this.eventAggregator = eventAggregator;
        if (_WEBPACK_IMPORT_.NETSUITE_ENVIRONMENT === 'sandbox') {
            this.urls = this.netsuite.externalUrlsSandbox;
        }
        else {
            this.urls = this.netsuite.externalUrlsProd;
        }
    }

    getCart() {
        return this.sendGet({
            url: this.urls['portal'],
            parameters: {
                aaa_action: 'cart-get'
            }
        }).then(function (response) {
            return response;
        });
    }

    addToCart(cartLine: CartLine) {
        console.log(cartLine);
        var line = cartLine.toObject();
        // var line = {
        //     id: cartLine.category.id,
        //     items: [],
        // };

        // if (cartLine.items && cartLine.items.length) {
        //     line.items = cartLine.items.map(i => i.toObject());
        //     // cartLine.items.forEach(function (ver) {
        //     //     let item = { id: ver.id, q: +ver.quantity };
        //     //     if (ver.subitems.length) {
        //     //         item.subitems = ver.subitems.map(i => i.toObject());
        //     //     }
        //     //     line.items.push(item);
        //     // });
        // }

        // var subitems = [];
        // cartLine.subItems.forEach(function (_subitem) {
        //     var subitem = {
        //         item: _subitem.item.id,
        //         qty: _subitem.quantity
        //     };
        //     subitems.push(subitem);
        // });
        // var subitemsJSON = '';
        // if (subitems.length) subitemsJSON = encodeURIComponent(JSON.stringify(subitems));

        return this.send({
            url: this.urls['portal'],
            parameters: {
                aaa_action: 'cart-add',
                aaa_item: line,
            }
        }).then(function (response) {
            return response;
        });
    }

    // editLineItem(cartLine: CartLine) {
    //     return this.send({
    //         url: this.urls['portal'],
    //         parameters: {
    //             aaa_action: 'editLineItem',
    //             // cust_line: cartLine.line,
    //             // cust_qty: cartLine.quantity
    //         }
    //     }).then(function (response) {
    //         // self.appState.itemCount += cartLine.quantity;
    //         // self.appState._cart.lines.push(cartLine);
    //         return response;
    //     });
    // }

    // removeLineItem(cartLine: CartLine) {
    //     return this.send({
    //         url: this.urls['portal'],
    //         parameters: {
    //             aaa_action: 'removeLineItem',
    //             // cust_line: cartLine.line,
    //             // cust_subitems: (cartLine.subItems.length) ? cartLine.subItems.map(i => i.line).join(',') : ''
    //         }
    //     }).then((response) => {
    //         // self.appState.itemCount += cartLine.quantity;
    //         // self.appState._cart.lines.push(cartLine);
    //         return response;
    //     });
    // }

    editLineItemVersion(cartLine: CartLine, ver: CartItem, sub?: CartItem, remove: boolean = false) {
        var line = cartLine.toObject();

        // var item = {
        //     id: cartLine.category.id,
        //     items: [],
        // }
        // if (cartLine.items && cartLine.items.length) {
        //     cartLine.items.forEach(function (ver) {
        //         item.items.push({ id: ver.item.id, q: ver.quantity });
        //     });
        // }

        let version;
        if (ver) version = { id: ver.item.id, q: ver.quantity };

        let subitem;
        if (sub) subitem = { id: sub.item.id, q: sub.quantity };

        return this.send({
            url: this.urls['portal'],
            parameters: {
                aaa_action: 'cart-edit-item',
                aaa_item: line,
                // aaa_line: cartLine.id,
                aaa_version: version,
                aaa_subitem: subitem,
                // aaa_qty: ver.quantity,
                aaa_remove: remove
            }
        }).then((response) => {
            return response;
        });
    }

    orderSummary(shipAddressId: string) {
        // console.log(cart);
        return this.sendGet({
            url: this.urls['portal'],
            parameters: {
                aaa_action: 'order-summary',
                aaa_shipaddress: shipAddressId,
            }
        }).then((response) => {
            return response;
        });
    }

    submitOrder(subtotal, shipAddressId: string) {
        // console.log(cart);
        return this.send({
            url: this.urls['portal'],
            parameters: {
                aaa_action: 'submit-order',
                aaa_shipaddress: shipAddressId,
                aaa_cart_subtotal: subtotal,
                // cust_id: cart.id,
                // cust_shipto: cart.shippingAddress.id,
                // cust_total: cart.total
            }
        }).then((response) => {
            return response;
        });
    }

    authenticate(email, password) {
        return this.sendGet({
            url: this.urls['auth'],
            parameters: {
                aaa_action: 'auth',
                aaa_email: email,
                aaa_pass: password,
                aaa_appid: '3',
            }
        }).then((response) => {
            return response;
        });
    }

    validateToken(authToken) {
        return this.sendGet({
            url: this.urls['auth'],
            parameters: {
                aaa_action: 'validate'
            }
        }).then((response) => {
            return response;
        });
    }

    getSiteConfig(siteId) {
        return this.sendGet({
            url: this.urls['portal'],
            parameters: {
                aaa_action: 'site-config'
            }
        }).then(function (response) {
            return response;
        });
        // return this.http.get('/resources/cache-SiteConfig_living-spaces.txt').then(httpResponse => { return JSON.parse(httpResponse.response) });
        // this.customScripts.push({
        //     scriptUrl: this.urls['siteConfig'],
        //     parameters: {
        //         cust_site: siteId
        //     }
        // });
        // return this.send();
    }

    // customScript(script, parameters) {
    //     this.customScripts.push({
    //         scriptUrl: this.urls[script],
    //         parameters: parameters
    //     });
    //     return this.send();
    // }

    // search(type, filters, columns: (string | string[])[], callback?, sortBy?: string) {
    //     this.addSearch(1, type, filters, columns, sortBy);
    //     return this.send().then(function (results) { if (callback) callback(null, results.searches[1]); return results.searches[1]; });
    // }

    // addSearch(name, type, filters, columns: (string | string[])[], sortBy?: string) {
    //     if (!type) throw 'Missing parameter: type';

    //     this.searches.push({
    //         name: name,
    //         type: type,
    //         filters: filters,
    //         columns: columns,
    //         sortBy: sortBy
    //     });
    // }

    // addSavedSearch(name, type, searchId, filters?, columns?: (string | string[])[], sortBy?: string) {
    //     if (!type) throw 'Missing parameter: type';

    //     this.searches.push({
    //         name: name,
    //         type: type,
    //         savedsearch: searchId,
    //         filters: filters,
    //         columns: columns,
    //         sortBy: sortBy
    //     });
    // }

    // fieldLookup(type, recordid, fields: string[], callback?, textfields?: string[]) {
    //     this.addFieldLookup('1', type, recordid, fields, textfields);
    //     return this.send().then(function (results) { if (callback) callback(null, results.fieldlookups[1]); return results.fieldlookups[1]; });
    // }

    // addFieldLookup(name: string, type: string, recordid, fields: string[], textfields?: string[]) {
    //     if (!type) throw 'Missing parameter: type';
    //     if (!recordid) throw 'Missing parameter: recordid';
    //     if (!fields) throw 'Missing parameter: fields';

    //     this.fieldlookups.push({
    //         name: name,
    //         type: type,
    //         recordid: recordid,
    //         fields: fields,
    //         textfields: textfields
    //     });
    // }

    send(config: any) {

        let parameters: any = {};
        if (config.parameters) parameters = config.parameters;
        parameters.aaa_token = localStorage.getItem("auth_token");

        return this.http.post(config.url, JSON.stringify(parameters))
            .then(response => {
                if (response.statusCode === 200) {
                    if (config.url === this.urls['portal'] && response.content.payload) return response.content.payload;
                    else return response.content;
                } else {
                    throw response.statusText;
                }
            });

    }

    sendGet(config: any) {

        let parameters: any = {};
        if (config.parameters) parameters = config.parameters;
        parameters.aaa_token = localStorage.getItem("auth_token");

        let url: string = config.url;
        url += (url.includes('?')) ? '&' : '?';
        url += this.serialize(parameters);

        return this.http.jsonp(url)
            .then(response => {
                if (response.statusCode === 200) {
                    if (config.url === this.urls['portal'] && response.content.payload) return response.content.payload;
                    else return response.content;
                } else {
                    throw response.statusText;
                }
            });

    }

    serialize(obj, prefix?) {
        var str = [];
        for (var p in obj) {
            if (obj.hasOwnProperty(p)) {
                var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
                str.push(typeof v == "object" ?
                    this.serialize(v, k) :
                    encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
        return str.join("&");
    }

    // Changes XML to JSON
    xmlToJson(xml) {
        // Create the return object
        var obj = {};

        if (xml.nodeType == 1) { // element
            // do attributes
            if (xml.attributes.length > 0) {
                obj["@attributes"] = {};
                for (var j = 0; j < xml.attributes.length; j++) {
                    var attribute = xml.attributes.item(j);
                    obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
                }
            }
        } else if (xml.nodeType == 3) { // text
            obj = xml.nodeValue;
        }

        // do children
        if (xml.hasChildNodes()) {
            for (var i = 0; i < xml.childNodes.length; i++) {
                var item = xml.childNodes.item(i);
                var nodeName = item.nodeName;
                if (typeof (obj[nodeName]) == "undefined") {
                    obj[nodeName] = this.xmlToJson(item);
                } else {
                    if (typeof (obj[nodeName].push) == "undefined") {
                        var old = obj[nodeName];
                        obj[nodeName] = [];
                        obj[nodeName].push(old);
                    }
                    obj[nodeName].push(this.xmlToJson(item));
                }
            }
        }
        return obj;
    };

    /**
     * XML2jsobj v1.0
     * Converts XML to a JavaScript object
     * so it can be handled like a JSON message
     *
    * By Craig Buckler, @craigbuckler, http://optimalworks.net
    *
    * As featured on SitePoint.com:
    * http://www.sitepoint.com/xml-to-javascript-object/
    *
    * Please use as you wish at your own risk.
    */
    XML2jsobj(node) {

        var data = {};

        // append a value
        function Add(name, value) {
            if (data[name]) {
                if (data[name].constructor != Array) {
                    data[name] = [data[name]];
                }
                data[name][data[name].length] = value;
            }
            else {
                data[name] = value;
            }
        };

        // element attributes
        var c, cn;
        if (node.attributes) {
            for (c = 0; cn = node.attributes[c]; c++) {
                Add(cn.name, cn.value);
            }
        }

        // child elements
        if (node.childNodes) {
            for (c = 0; cn = node.childNodes[c]; c++) {
                if (cn.nodeType == 1) {
                    if (cn.childNodes.length == 1 && cn.firstChild.nodeType == 3) {
                        // text value
                        Add(cn.nodeName, cn.firstChild.nodeValue);
                    }
                    else {
                        // sub-object
                        Add(cn.nodeName, this.XML2jsobj(cn));
                    }
                }
            }
        }

        return data;

    }
};



		/*jQuery.ajax({
		  url: this.netsuite.suitelet,
		  method: 'GET',
		  data: querystring,
		  dataType: 'jsonp',
		  processData: false
		  // xhrFields: { withCredentials: true },
		  // beforeSend: function (request)
		  // {
		  //     request.setRequestHeader("Authorization", 'NLAuth nlauth_account='+netsuite.account+',nlauth_email='+netsuite.email+',nlauth_signature='+netsuite.password+',nlauth_role='+netsuite.role);
		  // }
		})*/
		/*.done(function (result) {
		  if (result.isSuccess){
		  this.searches = [];
		  this.fieldlookups = [];
		  callback(null, result.content);
		}else{
		  callback(result.statusText)
		}
		}).fail(function (jqXHR, textStatus, err) { callback(err); });*/