import { autoinject } from 'aurelia-framework';
import { Redirect, NavigationInstruction, RouterConfiguration, Router, Next } from 'aurelia-router'
import { PLATFORM } from 'aurelia-pal';
import { ApplicationState } from './application-state';
import { EventAggregator } from 'aurelia-event-aggregator';
//import HttpClientConfig from 'aurelia-auth/app.httpClient.config';
//import AuthorizeStep from 'aurelia-auth';
import { Netsuite } from './services/netsuite-service';
// import { SiteConfig, UserProfile, Category, Item } from './models/site-config';

@autoinject()
export class App {

    configureRouter(config: RouterConfiguration) {
        config.title = 'AAA Flag & Banner';

        // config.addPipelineStep('authorize', AuthorizeStep);
        config.addPipelineStep('authorize', {
            run(navigationInstruction: NavigationInstruction, next) {
                if (navigationInstruction.getAllInstructions().some(i => i.config.settings.auth)) { //.some(i => i.config.settings.roles.indexOf('admin') !== -1)) {
                    var auth_token = localStorage.getItem("auth_token");
                    var isLoggedIn = (typeof auth_token !== "undefined" && auth_token !== null);
                    // console.log(navigationInstruction.getBaseUrl(), navigationInstruction.params.siteUrl, navigationInstruction);
                    var siteUrl = navigationInstruction.params.siteUrl;
                    if (!siteUrl) siteUrl = 'aaa';
                    if (!isLoggedIn) {
                        return next.cancel(new Redirect(siteUrl + '/login'));
                    }
                }
                return next();
            }
        });

        config.addPostRenderStep({
            run(navigationInstruction: NavigationInstruction, next: Next) {
                if (navigationInstruction.router.isNavigatingNew) {
                    window.scroll(0, 0);
                }
                return next();
            }
        });

        config.map([
            { route: '', redirect: 'aaa' },
            { route: 'aaa', name: 'root', moduleId: PLATFORM.moduleName('public-site') },
            //{ route: 'login',            name: 'login',          moduleId: 'login',          title: 'Login' },
            { route: ':siteUrl', name: 'branded-site', moduleId: PLATFORM.moduleName('branded-site'), settings: { auth: false } }
            // { route: [':siteUrl/c/:categoryUrl'], name: 'categories', moduleId: 'categories', title: 'Categories', nav: false },
            // { route: ':siteUrl/list', name: 'list', moduleId: 'product-list', title: 'Product List', nav: false },
            // { route: ':siteUrl/cart',    name: 'cart-view',   moduleId: 'cart-view', title:'Cart', nav: false }
        ]);
    }

    constructor(public appState: ApplicationState, public router: Router, public eventAggregator: EventAggregator, public netsuite: Netsuite) {
        // this.eventAggregator.subscribe('router:navigation:complete', payload => window.scrollTo(0, 0));
    }

    activate(params: ParameterDecorator, routeConfig: RouterConfiguration) {
        //var siteUrl = params.siteUrl;
        //this.httpClientConfig.configure();
        //console.log(this.appState);
        //return this.appState.loadSiteConfig('1').then(function(){console.log('finished loading siteConfig 1')});
        var auth_token = localStorage.getItem("auth_token");
        if (typeof auth_token !== "undefined" && auth_token !== null) {
            return this.appState.getProfile(auth_token).then((profile) => {
                if (profile) {
                    // var profile = new UserProfile(results.session.sessionToken);
                    // profile.firstName = results.session.firstname;
                    // profile.middleName = results.session.middlename;
                    // profile.lastName = results.session.lastname;
                    // profile.email = results.session.email;
                    // profile.addressbook = results.session.addressbook;
                    // this.appState.userProfile = profile;
                    // // console.log('profile', profile);
                    // this.appState.siteUrl = results.session.portalUrl;
                    // // console.log('SET siteUrl', self.appState.siteUrl);

                    // if (siteUrl != results.session.portalUrl) {
                    //     self.router.navigateToRoute('branded-site', { siteUrl: results.session.portalUrl });
                    // }

                    return this.appState.getSiteConfig(this.appState.siteUrl);
                }
            },
                (error) => {
                    console.error('App - activate()', error);
                    // localStorage.removeItem('auth_token');
                    // this.router.navigateToRoute('aaa');
                }
            );
        }
    }

}

// class AuthorizeStep {
//     run(navigationInstruction: NavigationInstruction, next) {
//         if (navigationInstruction.getAllInstructions().some(i => i.config.settings.auth)) { //.some(i => i.config.settings.roles.indexOf('admin') !== -1)) {
//             var isLoggedIn = AuthorizeStep.isLoggedIn();
//             // console.log(navigationInstruction.getBaseUrl(), navigationInstruction.params.siteUrl, navigationInstruction);
//             var siteUrl = navigationInstruction.params.siteUrl;
//             if (!siteUrl) siteUrl = 'aaa';
//             if (!isLoggedIn) {
//                 return next.cancel(new Redirect(siteUrl + '/login'));
//             }
//         }

//         return next();
//     }

//     static isLoggedIn(): boolean {
//         var auth_token = localStorage.getItem("auth_token");
//         return (typeof auth_token !== "undefined" && auth_token !== null);
//     }
// }
