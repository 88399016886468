import 'materialize-css';
import { Aurelia } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';

// import 'materialize-css/bin/materialize.css';
import '../static/materialize.scss';

// we want font-awesome to load as soon as possible to show the fa-spinner
// import 'font-awesome/css/font-awesome.css';
// import 'bootstrap/dist/css/bootstrap.css';
import '../static/styles.css';
import '../static/app.scss';

// comment out if you don't want a Promise polyfill (remove also from webpack.config.js)
import * as Bluebird from 'bluebird';
Bluebird.config({ warnings: false });

if (!String.prototype.includes) {
    String.prototype.includes = function (search, start) {
        'use strict';
        if (typeof start !== 'number') {
            start = 0;
        }

        if (start + search.length > this.length) {
            return false;
        } else {
            return this.indexOf(search, start) !== -1;
        }
    };
}

export async function configure(aurelia: Aurelia) {

    aurelia.use
        .standardConfiguration()
        .developmentLogging()
        .plugin(PLATFORM.moduleName('resources/index'))
        .plugin(PLATFORM.moduleName('aurelia-dialog'))
        // .plugin(PLATFORM.moduleName('aurelia-dialog'), config => {
        //     config.useDefaults();
        //     config.settings.lock = false;
        //     // config.settings.centerHorizontalOnly = false;
        //     config.settings.startingZIndex = 1005;
        // })
        .plugin(PLATFORM.moduleName('aurelia-materialize-bridge'), bridge => {
            // bridge.useAll()
            bridge
                .useCheckbox()
                // .useCollection()
                .useInput()
                .useModal()
                .useNavbar()
                .useProgress()
                // .useRange()
                .useSidenav()
                // .useCollapsible()
                .useTooltip()
                // .useTransitions()
                .useWaves()
                .useWell();
        })
    // .plugin(PLATFORM.moduleName('aurelia-google-analytics'), config => {
    //     config.init('UA-20998118-2');
    //     config.attach({
    //         logging: {
    //             enabled: true // Set to `true` to have some log messages appear in the browser console.
    //         },
    //         pageTracking: {
    //             enabled: true // Set to `false` to disable in non-production environments.
    //         },
    //         clickTracking: {
    //             enabled: false // Set to `false` to disable in non-production environments.
    //         }
    //     });
    // });

    // Uncomment the line below to enable animation.
    // aurelia.use.plugin('aurelia-animator-css');
    // if the css animator is enabled, add swap-order="after" to all router-view elements

    // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
    // aurelia.use.plugin('aurelia-html-import-template-loader')

    await aurelia.start();
    aurelia.setRoot(PLATFORM.moduleName('app'));

}
