import { Router } from 'aurelia-router';
import { inject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { ApplicationState } from './application-state';
import { Product } from './product';
import { SiteConfig, Category } from './models/site-config';

@inject(ApplicationState, EventAggregator, DialogService)
export class Home {
    appState: ApplicationState;
    eventAggregator;
    dialogService;
    categories: Map<string, Category>;
    assetsRootUrl;
    homeImage1: string;

    constructor(appState, eventAggregator, dialogService) {
        //console.log('CONSTRUCTOR home');
        this.eventAggregator = eventAggregator;
        this.dialogService = dialogService;
        this.appState = appState;
        //this.netsuite = netsuite;
        //this.categories = new Map<string, Category>();
        // var self = this;
        // this.appState.siteConfig.then(function(site) {
        //     self.categories = site.categories;
        //     self.assetsRootUrl = site.assetsRootUrl;
        //     self.homeImage1 = site.homeImage1;
        // });
    }

    activate(params, routeConfig) {
        //console.log('ACTIVATE', routeConfig);
        var self = this;
        // return this.appState.siteConfig.then(function(site) {
        //     self.categories = site.categories;
        //     self.assetsRootUrl = site.assetsRootUrl;
        //     self.homeImage1 = site.homeImage1;
        // });
    }
}

