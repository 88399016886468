import { inject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ApplicationState } from './application-state';
import { EventAggregator } from 'aurelia-event-aggregator';

@inject(ApplicationState, Router, EventAggregator)
export class NavBar {
    appState: ApplicationState;
    router: Router;
    eventAggregator: EventAggregator;
    clientLogo: string;

    constructor(appState, router, eventAggregator) {
        this.eventAggregator = eventAggregator;
        this.appState = appState;
        this.router = router;
        this.clientLogo = '';
    }

    attached() {
        this.appState.getSiteConfig().then((site) => {
            this.clientLogo = site.logoSm;
            //console.log('logo should be shown!', site.logoSm);
        });
    }

    signOut() {
        this.appState.signOut();
        // this.appState.userProfile = null;
        // this.appState.profile = null;
        // this.appState._cart = null;
        // localStorage.removeItem('auth_token');
        // this.router.navigateToRoute('branded-login');
    }
}
