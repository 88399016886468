import { Router } from 'aurelia-router';
import { inject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { ApplicationState } from './application-state';
// import { SiteConfig, UserProfile, Category, Item } from './models/site-config';
import { Netsuite } from './services/netsuite-service';

@inject(Netsuite, ApplicationState, Router, EventAggregator, DialogService)
export class Login {
    @bindable emailInput: HTMLInputElement;
    //@bindable submitButton: HTMLButtonElement;
    netsuite: Netsuite;
    appState: ApplicationState;
    router: Router;
    eventAggregator;
    dialogService;
    assetsRootUrl;
    homeImage1: string;
    email: string = '';
    password: string = '';
    processing: boolean;
    errorMsg: string = '';

    constructor(netsuite, appState, router, eventAggregator, dialogService) {
        this.netsuite = netsuite;
        this.router = router;
        this.eventAggregator = eventAggregator;
        this.dialogService = dialogService;
        this.appState = appState;
        this.netsuite = netsuite;
        // this.appState.siteConfig.then(function(site) {
        //     self.assetsRootUrl = site.assetsRootUrl;
        //     self.homeImage1 = site.homeImage1;
        // });
    }

    activate(params) {
        var auth_token = localStorage.getItem("auth_token");
        // console.log('auth_token', auth_token);
        // console.log('siteUrl', this.appState.siteUrl);
        if (typeof auth_token !== "undefined" && auth_token !== null) {
            if (this.appState.siteUrl) {
                // this.appState.userProfile = new UserProfile(auth_token);
                // console.log('navigateToRoute', this.appState.siteUrl);
                this.router.navigateToRoute('branded-site', { siteUrl: this.appState.siteUrl });
            }
        }
    }

    attached() {
        this.emailInput.focus();
        this.appState.getSiteConfig().then((site) => {
            this.homeImage1 = site.homeImage1;
            // this.clientLogo = site.logoSm;
            console.log('logo should be shown!', site);
        });
    }

    // activate(params, routeConfig) {
    //     var self = this;
    //     return this.appState.siteConfig.then(function(site) {
    //         self.categories = site.categories;
    //         self.assetsRootUrl = site.assetsRootUrl;
    //     });
    // }

    login() {
        this.errorMsg = '';
        this.processing = true;
        this.appState.login(this.email, this.password).then((profile) => {
            this.appState.getSiteConfig(this.appState.siteUrl);
            // this.appState.getCart();
            // if (self.appState.siteUrl && self.appState.siteUrl == results.session.portalUrl) {
            //     self.router.navigateToRoute('branded-home');
            // }
            // else {
            //     self.router.navigateToRoute('branded-site', { siteUrl: results.session.portalUrl });
            // }
            this.router.navigateToRoute('branded-site', { siteUrl: this.appState.siteUrl });
        }).catch(() => {
            this.processing = false;
            this.errorMsg = 'Invalid username and/or password';
        });
        // return this.auth.login(this.email, this.password)
        // .then(response=>{
        //     console.log("success logged " + response);
        // })
        // .catch(err=>{
        //     console.log("login failure");
        // });
    };

}
