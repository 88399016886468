import { SiteConfig, Category, Item, AddressModel } from './site-config';
import { computedFrom } from 'aurelia-binding';

export class Cart {
    id: string;
    // tranid: string;
    // trandate;
    lines: CartLine[];
    subtotal = 0;
    // discountTotal: string;
    // discountRate: string;
    taxtotal: string;
    total = 0;
    taxRate: string;
    shippingcost: string;
    shippingmethod: string;
    shippingAddress: AddressModel;
    itemCount = 0;
    // minimumOrderMet: boolean;

    constructor() {
        this.lines = [];
    }

    @computedFrom('subtotal')
    get minimumOrderMet() {
        return (this.subtotal >= 75) ? true : false;
    }

    fromJson = (_cart, siteConfig: SiteConfig) => {
        // siteConfig.itemCount = 0;
        if (_cart.lines && _cart.lines.length) {
            this.id = _cart.id;
            // cart.tranid = _cart.tranid;
            // cart.trandate = _cart.trandate;
            // cart.subtotal = _cart.subtotal;
            // cart.discountTotal = _cart.discounttotal;
            // cart.discountRate = _cart.discountrate;
            // cart.taxtotal = _cart.taxtotal;
            // cart.total = _cart.total;
            // cart.taxRate = _cart.shippingtax1rate;
            // cart.shippingcost = _cart.shippingcost;
            // cart.shippingmethod = _cart.shippingmethod;

            this.lines = _cart.lines.map((_line) => {
                let category = siteConfig.categoriesById.get(_line.category);
                console.log('new line', _line, category, siteConfig)
                var line = new CartLine(category);
                line.fromJson(_line, siteConfig, this);
                return line;
            });

            // if (this.subtotal >= 75) this.minimumOrderMet = true;
        }
    }

}

export class CartLine {
    // line: number;
    id: string;
    // groupId: number;
    // subof: number;
    // category: Category;
    // quantity: number = 0;
    description: string;
    // rate: string;
    amount = 0;
    items: CartItem[] = [];

    constructor(public category: Category) {
        if (this.category) {
            this.id = this.category.id;
            this.description = this.category.name;
        }
        else this.id = '';
    }

    fromJson = (_line, siteConfig: SiteConfig, cart: Cart) => {
        this.id = _line.id;
        // line.groupId = _item.grp;
        // if (_item.portalItem)
        // if (_line.id) this.category = siteConfig.categoriesById.get(_line.id);
        // else if (portalData.portalHardware)
        //     line.item = this._siteConfig.hardware.find((i) => i.id == portalData.portalHardware);

        this.items = _line.items.map(_item => {
            var item = new CartItem();
            item.fromJson(_item, siteConfig, cart, this);
            return item;
        });
    }

    toObject = () => {
        return {
            id: this.id,
            category: (this.category) ? this.category.id : '',
            items: this.items.map(i => i.toObject()),
        }
    }
}

export class CartItem {
    id: string;
    rate: number;
    amount: number;
    subitems: CartItem[] = [];

    constructor(public item?: Item, public quantity?: number) {
        if (this.item) this.id = this.item.id;
    }

    fromJson = (_item, siteConfig: SiteConfig, cart: Cart, cartLine: CartLine) => {
        this.item = siteConfig.itemMap.get(_item.id);
        this.id = this.item.id;
        this.quantity = +_item.q;
        this.rate = this.item.price;
        this.amount = this.rate * this.quantity;
        // siteConfig.itemCount += this.quantity;
        cart.itemCount += this.quantity;
        cartLine.amount += this.amount;
        cart.subtotal += this.amount;

        if (_item.subitems) this.subitems = _item.subitems.map(_subitem => {
            var item = new CartItem();
            item.fromJson(_subitem, siteConfig, cart, cartLine);
            return item;
        });
    }

    toObject = () => {
        return {
            id: this.id,
            q: +this.quantity,
            subitems: (this.subitems.length) ? this.subitems.map(i => i.toObject()) : undefined,
        }
    }
}
